import React from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import userDataStore, { UserState } from "../store/userDataStore";
import { useOutletContext, Link, Navigate } from "react-router-dom";
import DashBoardLoader from "../components/ui/loading/DashBoardLoader";
import AlertIsError from "../components/ui/warning/AlertIsError";
import images from "../styles/no-order.webp";
import BadgedIcon from "../components/ui/BadgedIcon";
import NoData from "../components/ui/warning/NoData";
import { _hasUserRole } from "../utils/functions";

const DashBoard = () => {
  //////////////////////////
  // booleans States
  /////////////////////////
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [isError, setIsError] = React.useState<boolean>(false);
  
  const [bookingGroup, setbookingGroup] = React.useState<any>([]);


  //////////////////////////
  // Store & context state
  /////////////////////////
  const isLogged = userDataStore((state: any) => state.isLogged);
  const dataStore = userDataStore((state: any) => state);

  const userRoles = userDataStore((state: UserState) => state.roles);


 

  const {
    setSelectedStore,
    setSelectedOrderCity,
    allSlot,
    setSelectedItem,
    orderReady,
    orderPickedUp,
    orderExpired,
  } = useOutletContext<any>();


  const hasSuperAdmin = _hasUserRole(userRoles, "ROLE_SUPER_ADMIN");
  const hasAdmin = _hasUserRole(userRoles, "ROLE_ADMIN");
 



  //////////////////////////
  // UseEffect
  /////////////////////////

  
  React.useEffect(() => {
    setIsLoading(true);
    setSelectedItem("");
  }, [dataStore.token, setSelectedItem]);

  React.useEffect(() => {
    if (allSlot && allSlot?.["hydra:member"]?.length > 0) {
      setIsLoading(false);
      setbookingGroup([...new Set(allSlot?.["hydra:member"]?.map((slot: any) => slot.company.name ))]) ;
    } else {
      if (allSlot && allSlot?.["hydra:member"]?.length < 0) {
        setIsError(true);
        setIsLoading(false);
      }
    }
  }, [allSlot]);
  
  
  return (
    <Container className="cde App text-center mt-2 mb-5 pb-5">
      {(!isLogged || !dataStore.token || !dataStore.company_name) && (
        <Navigate to="/connexion" />
      )}
      <div
        className="ff-agency text-green
       border border-2 border-gray rounded-pill  mt-2 mb-3 py-2"
      >
        <div className="text-center ps-0">Visualisation générale</div>
      </div>

      {isError ? (
        <Container className="text-center mt-5">
          <AlertIsError
            title="Une erreur s'est produite"
            msg="Vérifiez votre connexion internet ou contactez votre administrateur."
            colorIcon="danger"
          />
        </Container>
      ) : isLoading ? (
        <DashBoardLoader />
      ) : allSlot?.["hydra:member"]?.length > 0 ? (
        bookingGroup?.map((name: string, index: number) => (
          <React.Fragment key={index}>
            <div>{
            // (hasSuperAdmin || hasAdmin) && 
            name}</div>
            <Row xs={1} md={2} className="g-2  m-auto">
              {allSlot?.["hydra:member"]?.map(
                (slot: any, indx: any) =>
                  slot.company.name === name && (
                    <Col key={indx}>
                      <Card
                        className=" py-0 border-2 border-gray rounded bg-ui text-second"
                        onClick={() => {
                          setSelectedStore(
                            slot?.slot?.temperatureZone?.locker["@id"]
                          );
                          setSelectedOrderCity(
                            slot?.slot?.temperatureZone?.locker?.shortLocation
                          );
                        }}
                      >
                        <Link
                          to="/preparations"
                          className=" text-decoration-none"
                        >
                          {" "}
                          <Row className=" m-auto pe-4 d-flex">
                            <Col xs={2} className="m-auto flex py-2">
                              <BadgedIcon
                                slot={slot}
                                borderColor="gray"
                                imgSize="35px"
                                componentSize="35px"
                                design="indicator-right"
                              />
                            </Col>
                            <Col
                              xs={4}
                              className="m-auto text-ui-second text-center font-75"
                            >
                              <b className="">
                                {
                                  slot?.slot?.temperatureZone?.locker
                                    ?.shortLocation
                                }{" "}
                              </b>{" "}
                            </Col>
                            <Col xs={6} className=" text-end text-ui-second">
                              <Row className="">
                                <Col
                                  xs={12}
                                  className="mb- py-0 text-ui-second px-0 "
                                >
                                  <Row className="font-65 ">
                                    <Col xs={8} className="m-auto text-start">
                                      <i className="ri-list-check-3 fs-5 text-green "></i>{" "}
                                      Chargements :
                                    </Col>
                                    <Col
                                      xs={4}
                                      className="m-auto text-secondary"
                                    >
                                      {" "}
                                      <b className="font-85">
                                        {
                                          orderReady?.["hydra:member"]?.filter(
                                            (order: any) =>
                                              order.status ===
                                                "ready_for_delivery" &&
                                              order?.bookingSlot?.slot
                                                ?.temperatureZone.locker
                                                .shortLocation ===
                                                slot?.slot?.temperatureZone
                                                  ?.locker.shortLocation &&
                                              order?.bookingSlot?.slot
                                                ?.temperatureZone?.name ===
                                                slot?.slot?.temperatureZone
                                                  ?.name &&
                                              order?.bookingSlot?.slot?.size ===
                                                slot?.slot?.size &&
                                              order?.bookingSlot?.["@id"] ===
                                                slot?.["@id"]
                                          )?.length
                                        }
                                      </b>
                                    </Col>
                                  </Row>
                                </Col>
                                <Col
                                  xs={12}
                                  className="mb-1 py-0 text-ui-second px-0"
                                >
                                  <Row className="font-65">
                                    <Col xs={9} className="m-auto text-start">
                                      <i className="ri-truck-line fs-4 text-green "></i>{" "}
                                      Livraisons :
                                    </Col>
                                    <Col
                                      xs={3}
                                      className="m-auto text-secondary"
                                    >
                                      {" "}
                                      <b className="font-85">
                                        {
                                          orderPickedUp?.["hydra:member"]?.filter(
                                            (order: any) =>
                                              order.status === "picked_up" &&
                                              order?.bookingSlot?.slot
                                                ?.temperatureZone?.locker
                                                ?.shortLocation ===
                                                slot?.slot?.temperatureZone
                                                  ?.locker?.shortLocation &&
                                              order?.bookingSlot?.slot
                                                ?.temperatureZone?.name ===
                                                slot?.slot?.temperatureZone
                                                  ?.name &&
                                              order?.bookingSlot?.slot?.size ===
                                                slot?.slot?.size &&
                                              order?.shippedBy?.id ===
                                                dataStore?.id
                                          )?.length
                                        }
                                      </b>
                                    </Col>
                                  </Row>
                                </Col>
                                <Col className="py-0 text-dark px-0">
                                  <Row className="font-65">
                                    <Col xs={9} className="m-auto text-start">
                                      <i className="ri-inbox-unarchive-line fs-4 text-green"></i>{" "}
                                      Retraits :
                                    </Col>
                                    <Col
                                      xs={3}
                                      className="m-auto text-secondary "
                                    >
                                      {" "}
                                      <b className="font-85">
                                        {
                                          orderExpired?.["hydra:member"]?.filter(
                                            (order: any) =>
                                              order.status === "overtime" &&
                                              order?.bookingSlot?.slot
                                                ?.temperatureZone.locker
                                                .shortLocation ===
                                                slot?.slot?.temperatureZone
                                                  ?.locker.shortLocation &&
                                              order?.bookingSlot?.slot
                                                ?.temperatureZone?.name ===
                                                slot?.slot?.temperatureZone
                                                  ?.name &&
                                              order?.bookingSlot?.slot?.size ===
                                                slot?.slot?.size
                                          )?.length
                                        }
                                      </b>
                                    </Col>
                                  </Row>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </Link>
                      </Card>
                    </Col>
                  )
              )}
            </Row>
          </React.Fragment>
        ))
      ) : (
        <NoData
          images={images}
          isFilteredOrders={false}
          msg="Aucune Réservation"
          msg2=""
        />
      )}
    </Container>
  );
};

export default DashBoard;
