import React from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Modal,
  Row,
  Spinner,
  Table,
} from "react-bootstrap";
import { Navigate, useOutletContext } from "react-router-dom";
import newOrderDataStore from "../../store/newOrderDataStore";
import userDataStore, { UserState } from "../../store/userDataStore";
import Swal from "sweetalert2";
import logsStore from "../../store/logsStore";
import {
  _getLockerAvailability,
  _getOrdersByStatus,
  _hasUserRole,
  _iconFilter,
  _searchAnythingWithRegex,
  _strRandom,
} from "../../utils/functions";
import axios from "axios";
import AlertIsError from "../../components/ui/warning/AlertIsError";
import { getError } from "../../utils/errors/GetError";
import images from "../../styles/no-order.webp";
import DashBoardLoader from "../../components/ui/loading/DashBoardLoader";
import ClientService from "../../service/Client/ClientService";
import InfoTopBar from "./InfoTopBar2";
import { BrowserMultiFormatReader } from "@zxing/library";
import ProductForm from "../../components/newOrder/ProductForm2";
import ClientForm from "../../components/newOrder/ClientForm";
import LokcerCard2 from "../../components/newOrder/LockerCard2";
import { diversProd } from "../../data/products";
import { supabase } from "../../utils/supabaseClient";
import LokcerCard3 from "../../components/newOrder/LockerCard";
import LokcerCard from "../../components/newOrder/LockerCard";



const transformData = (data: any) => {
  const result: any = [];

  const companyMap: any = {};

  data.flat().forEach((bookingSlot: any) => {
    const companyName = bookingSlot.company.name;
    const locker = bookingSlot.slot.temperatureZone.locker;
    const temperatureZone = {
      "@id": bookingSlot["@id"],
      "myKey": bookingSlot.slot.temperatureZone.myKey,
      "name": bookingSlot.slot.temperatureZone.name,
      "keyTemp": bookingSlot.slot.temperatureZone.keyTemp,
      "size": bookingSlot.slot.size,
      "available": bookingSlot.available
    };

    if (!companyMap[companyName]) {
      companyMap[companyName] = {
        companyName,
        locker: {}
      };
    }

    const lockerId = locker["@id"];
    if (!companyMap[companyName].locker[lockerId]) {
      companyMap[companyName].locker[lockerId] = {
        "@id": lockerId,
        "type": locker.type,
        "location": locker.location,
        "shortLocation": locker.shortLocation,
        "temperatureZone": []
      };
    }

    companyMap[companyName].locker[lockerId].temperatureZone.push(temperatureZone);
  });

  Object.values(companyMap).forEach((company: any) => {
    company.locker = Object.values(company.locker);
    result.push(company);
  });

  return result;
};



const NewOrder3 = () => {
  //////////////////////////
  // Store & context state
  /////////////////////////
  const dataStore = userDataStore((state: any) => state);
  const newOrderRegister = newOrderDataStore(
    (state: any) => state.newOrderRegister
  );
  const newOrderDelete = newOrderDataStore(
    (state: any) => state.newOrderDelete
  );
  const orderStore = newOrderDataStore((state: any) => state);

  const logs = logsStore((state: any) => state);
  const logCatcher = logsStore((state: any) => state.logCatcher);

  const userRoles = userDataStore((state: UserState) => state.roles);


  const [transformedData, setTransformedData] = React.useState<any>([]);


  const now: any = Date.now();

  const { setOrderReady, allSlot } = useOutletContext<any>();

  //////////////////////////
  // booleans States
  /////////////////////////
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [isOrderCreate, setIsOrderCreate] = React.useState<boolean>(false);
  const [isError, setIsError] = React.useState<boolean>(false);
  const [isMsgErrorName, setIsMsgErrorName] = React.useState<boolean>(false);
  const [isMsgErrorEmail, setIsMsgErrorEmail] = React.useState<boolean>(false);
  const [trigger, setTrigger] = React.useState<boolean>(false);
  const [trigger2, setTrigger2] = React.useState<boolean>(false);
  const [isValidPhone, setIsValidPhone] = React.useState<boolean>(true);
  const [isValidPhone2, setIsValidPhone2] = React.useState<boolean>(true);
  const [showProd, setShowProd] = React.useState<boolean[]>([]);

  //////////////////////////
  // States
  /////////////////////////

  const [qtySlot, setQtySlot] = React.useState<any>(1);
  const [clientEmail, setClientEmail] = React.useState<any>("");
  const [clientName, setClientName] = React.useState<any>("");
  const [clientLastName, setClientLastName] = React.useState<any>("");
  const [clientPhone, setClientPhone] = React.useState<any>("");
  const [filteredEmail, setFilteredEmail] = React.useState<any>([]);
  const [filteredName, setFilteredName] = React.useState<any>([]);
  const [filteredLastName, setFilteredLastName] = React.useState<any>([]);
  const [filteredPhone, setFilteredPhone] = React.useState<any>([]);
  const [autoCompletTab, setAutoCompletTab] = React.useState<any>([]);
  const [choosedName, setChoosedName] = React.useState<any>("");
  const [choosedLastName, setChoosedLastName] = React.useState<any>("");
  const [choosedEmail, setChoosedEmail] = React.useState<any>("");
  const [choosedPhone, setChoosedPhone] = React.useState<any>("");

  const [uniqueTab, setUniqueTab] = React.useState<any>([]);
  const [shortLocationTab, setShortLocationTab] = React.useState<any>([]);
  const [chosenLocker, setChosenLocker] = React.useState<any>([]);

  const [bookingSlotIds, setBookingSlotIds] = React.useState<any>([]);
  const [tempZones, setTempZones] = React.useState<any>([]);
  const [nameTempZones, setNameTempZones] = React.useState<any>([]);
  const [slotSizes, setSlotSizes] = React.useState<any>([]);
  const [productDetail, setProductDetail] = React.useState<any>([]);
  const [productOrder, setProductOrder] = React.useState<any>([]);
  const [ageRestrict, setAgeRestrict] = React.useState<any>([]);
  const [ageRestriction, setAgeRestriction] = React.useState<boolean>(false);

  const [msgError, setMsgError] = React.useState<any>();
  const [codeError, setCodeError] = React.useState<any>();

  const [show, setShow] = React.useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [showUnknowEan, setShowUnknowEan] = React.useState(false);

  const handleCloseUnknowEan = () => setShowUnknowEan(false);
  const handleShowUnknowEan = () => setShowUnknowEan(true);

  const [indxScan, setIndxScan] = React.useState<number>(0);
  const [indexScan, setIndexScan] = React.useState<number>(0);
  const [isScanned, setIsScanned] = React.useState<boolean>(false);
  const [unknowScan, setUnknowScan] = React.useState<any>();
  
  const [prod, setProd] = React.useState<any>([]);

  
  const hasSuperAdmin = _hasUserRole(userRoles, "ROLE_SUPER_ADMIN");
  const hasAdmin = _hasUserRole(userRoles, "ROLE_ADMIN");
  // ⚠  change ROLE_USER to ROLE_TECH on prod
  const hasTech = _hasUserRole(userRoles, "ROLE_SHIPPER");
  // const hasTech = _hasUserRole(userRoles, "ROLE_TECH");

const [bookingGroup, setbookingGroup] = React.useState<any>([]);
const [slotGroup, setSlotGroup] = React.useState<any>([]);


 

  ////////////////////////
  //Regex pour vérifier que le numéro de téléphone du client commence par 87 ou 88 ou 89
  ///////////////////////
  const regex = /^(87|89|88)\d+$/;

  //////////////////////////
  // UseEffect
  /////////////////////////

  
  React.useEffect(() => {
    if (trigger2) {
      getClientByCompany(dataStore.token, dataStore.company_name);
    }
  }, [trigger2]);
  
  React.useEffect(() => {
    setIsValidPhone(regex.test(choosedPhone ? choosedPhone : clientPhone));
    
    if (clientPhone === "") {
      setIsValidPhone2(true);
      setIsValidPhone(true);
    }
  }, [clientPhone]);
  
  

  React.useEffect(() => {
    const bookingLocker: any = allSlot?.["hydra:member"]?.map(
      (locker: any) => locker?.slot?.temperatureZone?.locker
    );
    const deduplicate: any = [
      ...new Set(bookingLocker?.map((locker: any) => locker?.location)),
    ];
    setUniqueTab(deduplicate);
    const bookingLocker2: any = allSlot?.["hydra:member"]?.map(
      (locker: any) => locker?.slot?.temperatureZone?.locker
    );
    const deduplicate2: any = [
      ...new Set(bookingLocker2?.map((locker: any) => locker?.shortLocation)),
    ];
    setShortLocationTab(deduplicate2);
  }, [allSlot]);

  React.useEffect(() => {
    _searchAnythingWithRegex(
      clientName,
      autoCompletTab?.["hydra:member"],
      setFilteredName,
      "firstname"
    );
    _searchAnythingWithRegex(
      clientLastName,
      autoCompletTab?.["hydra:member"],
      setFilteredLastName,
      "lastname"
    );

    _searchAnythingWithRegex(
      clientEmail,
      autoCompletTab?.["hydra:member"],
      setFilteredEmail,
      "email"
    );
    _searchAnythingWithRegex(
      clientPhone,
      autoCompletTab?.["hydra:member"],
      setFilteredPhone,
      "phone"
    );
  }, [clientName, clientLastName, clientEmail, clientPhone, autoCompletTab]);

  React.useEffect(() => {
    if (allSlot && allSlot["hydra:member"]?.length > 0) {
      setbookingGroup([...new Set(allSlot?.["hydra:member"]?.map((slot: any) => slot.company.name ))]) ;
    } 
    
   
    
  }, [allSlot]);
  React.useEffect(() => {
    
   let newTab: any = [];
    bookingGroup.forEach((booking: any, indx: number) => {
      const filtered = allSlot && allSlot["hydra:member"].filter((slot: any) => slot.company.name === booking)
       newTab = [...newTab, filtered]
    })
    setSlotGroup(newTab);

    
  }, [bookingGroup]);


  React.useEffect(() => {
    setTransformedData(transformData(slotGroup));
  }, [slotGroup]);

  //////////////////////////
  // Events
  /////////////////////////

  // Fonctions pour scanner les ean des produits
  const videoRef: any = React?.useRef(null);

  const startScan = async (indx : number, index: number) => {
    
    setIsScanned(true);
    try {
      const codeReader = new BrowserMultiFormatReader();
      const constraints = {
        video: {
          facingMode: "environment",
        },
      };
    
      const stream = await navigator.mediaDevices.getUserMedia(constraints);
      videoRef.current.srcObject = stream;
      let scanning = true;
      codeReader?.decodeFromVideoDevice(
        null,
        videoRef.current,
       async (result: any) => {
         if (scanning && result?.text) {
           scanning = false;
           await getProd(result?.text, indx, index, handleChangeProductScan);
         }
        }
      );
    } catch (error) {
      console.error("Erreur lors de la configuration de la caméra:", error);
    }
  };



  const stopScan = () => {
    const stream = videoRef.current?.srcObject;
    if (stream) {
      const tracks = stream.getTracks();
      tracks.forEach((track: any) => track.stop());
      videoRef.current.srcObject = null;
      setIsScanned(false);
    }
  };

  const handleChangeProductScan = (
    data: any,
    indx: any,
    index: any,
    productDetail: any,
    setProductDetail: any
  ) => {
    const newProductDetail2 = [...productDetail];

    if (newProductDetail2[indx] && newProductDetail2[indx][index]) {
      newProductDetail2[indx][index] = {
        id: Math.random(),
        name: data[0]?.Libelle,
        price: data[0]?.pv,

        quantity: 1,
      };
      stopScan();
      setIsScanned(false);
      setProductDetail(newProductDetail2);
    } else {
      stopScan();
      setIsScanned(false);
    }
  };

  const getClientByCompany = (token: string, company: string) => {
    ClientService.getClientsByCompany(token, company)
      .then((response: any) => {
        setAutoCompletTab(response.data);
      })
      .catch((error: any) => {
        setIsError(true);
        setMsgError(getError(error));
        setCodeError(error.status);
        console.log(error);
      });
  };

  const popUpError = (code: any, text: any) => {
    Swal.fire({
      position: "top-end",
      background: "rgb(238, 195, 195)",
      toast: true,
      icon: "error",
      title: `Erreur : ${code}`,
      text: text,
      showConfirmButton: false,
      timer: 4000,
    });
    newOrderDelete();
    setTempZones([]);
    setSlotSizes([]);
    setBookingSlotIds([]);
    setTrigger(false);
    setChosenLocker([]);
    setClientName("");
    setClientEmail("");
    setChoosedName("");
    setChoosedEmail("");
    setClientPhone("");
    setAgeRestriction(false);
  };

  const removeAgeRestriction = (orders: any) => {
    if (qtySlot === 1) {
      if (ageRestrict && ageRestrict[0] === false) {
        delete orders.ageRestriction;
      }
      return orders;
    } else if (qtySlot > 1) {
      return orders?.map((order: any, indx: number) => {
        const newOrder = { ...order };
        if (ageRestrict && ageRestrict[indx] === false) {
          delete newOrder.ageRestriction;
        }
        return newOrder;
      });
    }
  };

  const createNewOrder = () => {
    function entierAleatoire(min: any, max: any) {
      return Math.floor(Math.random() * (max - min + 1)) + min;
    }
    const multiOrderCode = _strRandom("popopopp").toLocaleUpperCase();
    const randomCode = _strRandom("popopopo").toLocaleUpperCase();
    const randomCodeMultiOrder = _strRandom("popopop").toLocaleUpperCase();
    const receiveCode = entierAleatoire(10000000, 99999999);

    setIsOrderCreate(true);
    let dataOrder: any =
      qtySlot === 1
        ? {
            barcode:
              chosenLocker[0]?.company?.cleveronCompanyId + "-" + randomCode,
            bookingSlot: bookingSlotIds[0],
            receiveCode: `${receiveCode}`,
            hold: 2880,
            multiOrderCode: null,
            products: productOrder[0],
            ageRestriction: 18,
            client: {
              firstname: choosedName
                ? choosedName
                : clientName?.name
                ? clientName?.name
                : clientName,
              lastname: choosedLastName
                ? choosedLastName
                : clientLastName?.name
                ? clientLastName?.name
                : clientLastName,
              email: choosedEmail
                ? choosedEmail
                : clientEmail?.email
                ? clientEmail?.email
                : clientEmail,
              phone: choosedPhone
                ? choosedPhone
                : clientPhone?.phone
                ? clientPhone?.phone
                : clientPhone,
            },

          }
        : Array.from({ length: qtySlot }).map((_, indx) => ({
            barcode:
              chosenLocker[0]?.company?.cleveronCompanyId +
              "-" +
              randomCodeMultiOrder +
              (indx + 1),
            bookingSlot: bookingSlotIds[indx],
            receiveCode: `${receiveCode}`,
            hold: 2880,
            multiOrderCode: multiOrderCode,
            products: productOrder[indx],
            ageRestriction: 18,
            client: {
              firstname: choosedName
                ? choosedName
                : clientName?.name
                ? clientName?.name
                : clientName,
              lastname: choosedName
                ? choosedName
                : clientLastName?.name
                ? clientLastName?.name
                : clientLastName,
              email: choosedEmail
                ? choosedEmail
                : clientEmail?.email
                ? clientEmail?.email
                : clientEmail,
              phone: choosedPhone
                ? choosedPhone
                : clientPhone?.phone
                ? clientPhone?.phone
                : clientPhone,
            },
          }));
         

    if (qtySlot === 1) {
    }
    dataOrder = removeAgeRestriction(dataOrder);

    if (qtySlot === 1) {
      let config = {
        method: "post",
        url: process.env.REACT_APP_END_POINT + "orders",
        headers: {
          Authorization: "Bearer " + dataStore.token,
          "Content-Type": "application/json",
        },
        data: dataOrder,
      };

      axios
        .request(config)
        .then((response: any) => {
          //this function update created order to status ready_for_delivery
          let data = JSON.stringify({
            status: "ready_for_delivery",
          });

          let config = {
            method: "patch",
            maxBodyLength: Infinity,
            url: process.env.REACT_APP_END_POINT + "orders/" + response.data.id,
            headers: {
              "Content-Type": "application/merge-patch+json",
              Authorization: "Bearer " + dataStore.token,
            },
            data: data,
          };

          axios
            .request(config)
            .then((response: any) => {
              console.log(JSON.stringify(response.data));
            })
            .catch((error: any) => {
              console.log(error);
            });

          _getOrdersByStatus(
            dataStore.token,
            "ready_for_delivery",
            setOrderReady,
            setIsLoading
          );
          handleClose();
          newOrderDelete();
          setTempZones([]);
          setSlotSizes([]);
          setBookingSlotIds([]);
          setQtySlot("");
          setIsOrderCreate(false);
          setTrigger(false);
          setChosenLocker([]);
          setClientName("");
          setClientLastName("");
          setClientEmail("");
          setChoosedName("");
          setChoosedLastName("");
          setChoosedEmail("");
          setTrigger2(false);
          setClientPhone("");
          setChoosedPhone("");
          setAgeRestriction(false);
          Swal.fire({
            position: "top-end",
            toast: true,
            icon: "success",
            title: "Commande validée",
            text:
              chosenLocker[0]?.company?.cleveronCompanyId + "-" + randomCode,
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        })
        .catch((error: any) => {
          console.log(error);
          handleClose();
          setMsgError(getError(error));
          setQtySlot("");
          setTrigger(false);
          setChosenLocker([]);
          setIsOrderCreate(false);
          setClientName("");
          setClientLastName("");
          setClientEmail("");
          setChoosedName("");
          setChoosedLastName("");
          setChoosedEmail("");
          setTrigger2(false);
          setClientPhone("");
          setChoosedPhone("");
          setAgeRestriction(false);
          if (logs.logApp) {
            logCatcher(
              logs?.logApp +
                " / date :" +
                now +
                "-" +
                error.response?.statusText
            );
          } else {
            logCatcher("date :" + now + "-" + error?.response?.statusText);
          }

          popUpError(
            error.response?.status,
            error.response?.data["hydra:description"]
          );
        });
    } else {
      let config: any = {
        method: "post",
        url: process.env.REACT_APP_END_POINT + "orders",
        headers: {
          Authorization: "Bearer " + dataStore.token,
          "Content-Type": "application/json",
        },
      };

      let promises = [];

      for (let i = 0; i < dataOrder?.length; i++) {
        config.data = dataOrder[i];
        promises.push(axios.request(config));
      }

      Promise.all(promises)
        .then((responses) => {
          const numOrder = responses?.map((num: any) => num?.data?.barcode);

          handleClose();
          newOrderDelete();
          setTempZones([]);
          setSlotSizes([]);
          setBookingSlotIds([]);
          setQtySlot("");

          let data = JSON.stringify({
            status: "ready_for_delivery",
          });
          responses.forEach((responseId) => {
            let config = {
              method: "patch",
              maxBodyLength: Infinity,
              url:
                process.env.REACT_APP_END_POINT +
                "orders/" +
                responseId?.data?.id,
              headers: {
                "Content-Type": "application/merge-patch+json",
                Authorization: "Bearer " + dataStore.token,
              },
              data: data,
            };

            axios
              .request(config)
              .then((response: any) => {
                console.log(JSON.stringify(response.data));
              })
              .catch((error: any) => {
                console.log(error);
              });
          });

          _getOrdersByStatus(
            dataStore.token,
            "ready_for_delivery",
            setOrderReady,
            setIsLoading
          );
          setIsOrderCreate(false);
          setClientName("");
          setClientLastName("");
          setClientEmail("");
          setChoosedName("");
          setChoosedLastName("");
          setChoosedEmail("");
          setAgeRestriction(false);
          setTrigger(false);
          setChosenLocker([]);
          Swal.fire({
            position: "top-end",
            toast: true,
            icon: "success",
            title: "Commande(s) validée(s)",
            text: `${numOrder}`,
            showConfirmButton: false,
            timer: 7000,
            timerProgressBar: true,
          });
        })
        .catch((error) => {
          handleClose();
          logCatcher(error.response.statusText);
          setMsgError(getError(error));
          popUpError(
            error.response.status,
            error.response.data["hydra:description"]
          );
          setIsOrderCreate(false);
          setQtySlot("");

          setTrigger(false);
          setChosenLocker([]);
          setClientName("");
          setClientLastName("");
          setClientEmail("");
          setChoosedName("");
          setChoosedLastName("");
          setChoosedEmail("");
          setAgeRestriction(false);
          if (logs.logApp) {
            logCatcher(
              logs.logApp + " / date :" + now + "-" + error.response.statusText
            );
          } else {
            logCatcher("date :" + now + "-" + error.response.statusText);
          }
        });
    }
  };

  const newOrderModal = async (e: any) => {
    e.preventDefault();
    handleShow();
  };

  const cancelNewOrder = () => {
    Swal.fire({
      position: "top-end",
      toast: true,
      icon: "error",
      title: "Commande non finalisée",
      showConfirmButton: false,
      timer: 3000,
    });
    handleClose();
    newOrderDelete();
    setTempZones([]);
    setSlotSizes([]);
    setBookingSlotIds([]);
    // setQty("");
    setTrigger(false);
    setTrigger2(false);
    setClientName("");
    setClientLastName("");
    setClientEmail("");
    setChoosedName("");
    setChoosedEmail("");
    setClientPhone("");
    setChoosedPhone("");
    setChosenLocker([]);
    setAgeRestriction(false);
    setProductDetail([]);
  };

  const validOrder = (e: any) => {
    productDetail.forEach((items: any) => {
      // Remplacer name vide par "Non renseigné"
      items.forEach((item: any) => {
        if (item.name === "") {
          item.name = "Non renseigné";
        }

        // Remplacer quantity null par 1
        if (item.quantity === null) {
          item.quantity = 1;
        }
      });
    });

    e.preventDefault();
    dataOrderFormat();
    if (!qtySlot) {
    }

    if (qtySlot) {
      setIsError(false);
      setIsMsgErrorName(false);
      setIsMsgErrorEmail(false);

      newOrderRegister(
        orderStore.lockerId,
        orderStore.location,
        null,
        orderStore.companyId,
        orderStore.companyName,
        orderStore.lockerType,
        orderStore.delivererId,
        tempZones,
        null,
        orderStore.keyTemp,
        null,
        slotSizes,
        qtySlot,
        ageRestriction === true && 18
      );
      setTrigger2(true);
    }
  };

  const filteredLocker = (locker: any, nameCompany: string) => {
    // console.log(nameCompany)
    setChosenLocker(
      allSlot?.["hydra:member"].filter(
        (slots: any) =>
          slots?.slot?.temperatureZone?.locker?.location === locker 
        && 
        slots?.company?.name === nameCompany 

      )
    );
  };


  //update select field when they change
  const handleChangeSelect = (e: any, indx: any) => {
    //conditions si "e.currentTarget.value" est vide
    if (e.currentTarget.value.trim() !== "") {
      try {
        const zone: any = JSON.parse(e.currentTarget.value);

        const newTab: any = [...tempZones];
        newTab[indx] = zone?.slot?.temperatureZone?.name;
        setTempZones(newTab);

        const newTabName: any = [...nameTempZones];
        newTabName[indx] = zone?.slot?.temperatureZone?.name;
        setNameTempZones(newTabName);

        const newTabSize: any = [...slotSizes];
        newTabSize[indx] = zone.slot?.size;
        setSlotSizes(newTabSize);

        const newTabBooking: any = [...bookingSlotIds];
        newTabBooking[indx] = zone["@id"];
        setBookingSlotIds(newTabBooking);
        newOrderRegister(
          zone?.slot?.temperatureZone?.locker["@id"],
          zone?.slot?.temperatureZone?.locker?.location,
          bookingSlotIds,
          dataStore?.company_id,
          zone?.company?.name,
          zone?.slot?.temperatureZone?.locker?.type,
          dataStore?.id,
          tempZones,
          zone?.slot?.temperatureZone?.myKey,
          slotSizes,
          qtySlot,
          ageRestriction === true && 18
        );
      } catch (error) {
        console.error("Erreur lors du traitement des données JSON :", error);
      }
    } else {
      console.error("Les données JSON sont vides.");
    }
  };

  //add empty field for first product
  const handleAddStartProduct = () => {
    const newTab = Array.from({ length: qtySlot }).map((_, index) => [
      {
        id: Math.random(),
        name: "",
        price: null,
        quantity: null,
      },
    ]);
    setProductDetail((prevProductDetail: any) => [
      ...prevProductDetail,
      ...newTab,
    ]);

    setTempZones(["null"]);
    setShowProd([false]);
  };

  //add a basket
  const handleAddSlotProduct = (indx: number) => {
    //Add a basket
    let newTab: any[] = [...productDetail];
    newTab[indx] = [
      {
        id: Math.random(),
        name: "",
        price: null,
        quantity: null,
      },
    ];
    setProductDetail(newTab);

    //Save basket temp
    let newTemp: string[] = [...tempZones];
    newTemp[indx] = "null";
    setTempZones(newTemp);

    //Save a new showProd state
    let newShowProd: boolean[] = [...showProd];
    newShowProd[indx] = false;
    setShowProd(newShowProd);
  };

  //add a prod in existing basket
  const handleAddProduct = (indx: any) => {
    let newTab: any[] = [...productDetail];
    newTab[indx][productDetail[indx]?.length] = {
      id: Math.random(),
      name: "",
      price: null,
      quantity: null,
    };
    setProductDetail(newTab);
  };

  //Delete prod in chosen basket
  const handleDeleteProduct = (indx: any, id: any) => {
    const newList = [...productDetail];
    newList[indx] = productDetail[indx]?.filter((prod: any) => prod?.id !== id);
    setProductDetail(newList);
  };

  //Delete last basket
  const handleDeleteSlot = (indx: any) => {
    let newList = productDetail.slice(indx, 1);
    setQtySlot(qtySlot - 1);
    setProductDetail(newList);

    tempZones.pop();
  };

  //update product field when they change
  const handleChangeProduct =  (
    e: any,
    indx: number,
    index: number,
    key: string,
    productDetail: any[][],
    setProductDetail: React.Dispatch<React.SetStateAction<any[][]>>
  ) => {
    const { value } = e.target;

    const newProductDetail2 = [...productDetail];

    const myScanData = diversProd.filter((produit) => {
      for (const ean of produit.ean) {
        if (ean === value) {
          return true;
        }
      }
      return false;
    })[0];
    if (value?.length === 13) {
      // if (newProductDetail2[indx] && newProductDetail2[indx][index]) {
      //   newProductDetail2[indx][index] = {
      //     // name: prod[0]?.Libelle,
      //     // price: prod[0]?.pv,
      //     name: myScanData?.name,
      //     price: myScanData?.price,
      //     quantity: 1,
      //   };
      // }
      // setProductDetail(newProductDetail2);
      getProd(value, indx, index, handleChangeProductScan)
    } else {
      if (newProductDetail2[indx] && newProductDetail2[indx][index]) {
        newProductDetail2[indx][index][key] =
          key === "quantity" || key === "price" ? parseInt(value) : value;
        setProductDetail(newProductDetail2);
      }
    }
  
  };


  //convert product in api format
  const dataOrderFormat = () => {
    setProductOrder(
      productDetail?.map((item: any) => {
        // Pour chaque tableau, créer un nouveau tableau sans le champ "id"
        return item?.map(({ id, ...rest }: any) => rest);
      })
    );
  };

  //marks the different stages of the order
  const bgClasses = ["bg-green", "bg-warning", "bg-secondary"];

  let rowClasses: any = [];

  if (chosenLocker?.length === 0) {
    rowClasses = [bgClasses[1], bgClasses[2], bgClasses[2]];
  } else if (!trigger2) {
    rowClasses = [bgClasses[0], bgClasses[1], bgClasses[2]];
  } else {
    rowClasses = [bgClasses[0], bgClasses[0], bgClasses[1]];
  }

  const handleFirstStepClick = () => {
    setChosenLocker([]);
    newOrderRegister(
      null,
      orderStore.location,
      null,
      orderStore.companyId,
      orderStore.companyName,
      orderStore.lockerType,
      orderStore.delivererId,
      null,
      null,
      null,
      0,
      0
    );
  };

  const handleSecondStepClick = () => {
    setChosenLocker([]);
    newOrderRegister(
      null,
      orderStore.location,
      null,
      orderStore.companyId,
      orderStore.companyName,
      orderStore.lockerType,
      orderStore.delivererId,
      null,
      null,
      null,
      0,
      0
    );
    setTrigger(false);
    setQtySlot(1);
    setProductDetail([]);
    setAgeRestrict([]);
  };

  const handleThirdStepClick = () => {
    setTrigger2(false);
    setClientPhone("");
    setChoosedPhone("");
  };

  //Check locker availablility
  const totalLockersAvailability: any = allSlot?.["hydra:member"]
    ?.filter(
      (lockers: any) =>
        lockers?.slot?.temperatureZone?.locker &&
        lockers?.slot?.temperatureZone?.locker["@id"] ===
          chosenLocker[0]?.slot?.temperatureZone?.locker["@id"]
    )
    ?.reduce((acc: any, current: any) => acc + current.available, 0);

  const lockerAvailability: any = allSlot?.["hydra:member"]?.filter(
    (lockers: any) =>
      lockers?.slot?.temperatureZone?.locker &&
      lockers?.slot?.temperatureZone?.locker["@id"] ===
        chosenLocker[0]?.slot?.temperatureZone?.locker["@id"]
  );

  const availabilityZone = (zone: string) =>
    allSlot?.["hydra:member"]
      ?.filter(
        (lockers: any) =>
          lockers?.slot?.temperatureZone?.locker &&
          lockers?.slot?.temperatureZone?.locker["@id"] ===
            chosenLocker[0]?.slot?.temperatureZone?.locker["@id"]
      )
      ?.filter(
        (lockers: any) => lockers?.slot?.temperatureZone?.name === zone
      )[0]?.available;

  //get locker availability by temp
  const lockerAvailabilityFresh = _getLockerAvailability(
    "Froid positif",
    availabilityZone
  );

  const lockerAvailabilityAmbiant = _getLockerAvailability(
    "Ambiant",
    availabilityZone
  );

  const lockerAvailabilityFreeze = _getLockerAvailability(
    "Froid négatif",
    availabilityZone
  );

  //availability objet
  const dispoTab = {
    total: totalLockersAvailability,
    fresh: lockerAvailabilityFresh,
    ambiant: lockerAvailabilityAmbiant,
    Freeze: lockerAvailabilityFreeze,
  };


 async function getProd( ean: string, indx: number,
  index: number, handleChangeProductScan: ( 
  products: any, 
  indx: number,
  index: number,
  productDetail: any,
  setProductDetail: any) => void){
  try {

    const { data: products, error } = await supabase
      .from('products')
      .select('*')
      .eq('EAN', ean)
      // .single();

    if (error) {
      console.error(error);
      throw error; // Re-throw the error to signal potential issues
    }


    

      if (products && products?.length > 0) {
        setProd(products);
        handleChangeProductScan(
          products,
          indx,
          index,
          productDetail,
          setProductDetail
        );

        setIsScanned(false);
      } else {
        setIsScanned(false);
        handleShowUnknowEan();
        setUnknowScan(ean);
      }
} catch (error) {
  console.error('Error fetching product:', error);
  throw error; // Re-throw for further handling (optional)
}
 }

  ////////////////
  //props
  ////////////////

  const infoToBarProps = {
    chosenLocker,
    trigger,
    trigger2,
    handleFirstStepClick,
    handleSecondStepClick,
    handleThirdStepClick,
  };

  const lockerCardProps = {
    transformedData,
    filteredLocker,
    setTrigger,
    handleAddStartProduct,
    setAgeRestriction,
  };

  const productFormProps = {
    isOrderCreate,
    validOrder,
    handleChangeSelect,
    chosenLocker,
    qtySlot,
    setQtySlot,
    productDetail,
    setProductDetail,
    handleChangeProduct,
    handleDeleteProduct,
    handleAddProduct,
    ageRestrict,
    setAgeRestrict,
    setIndxScan,
    setIndexScan,
    startScan,
    handleAddSlotProduct,
    handleDeleteSlot,
    dispoTab,
    tempZones,
    showProd,
    setShowProd,
  };

  const clientFormProps = {
    clientPhone,
    setClientPhone,
    choosedPhone,
    setChoosedPhone,
    choosedName,
    choosedLastName,
    setChoosedName,
    setChoosedLastName,
    clientName,
    clientLastName,
    clientEmail,
    setClientEmail,
    setClientName,
    setClientLastName,
    choosedEmail,
    setChoosedEmail,
    setIsValidPhone2,
    setIsValidPhone,
    newOrderModal,
    filteredName,
    filteredLastName,
    setFilteredName,
    setFilteredLastName,
    filteredEmail,
    setFilteredEmail,
    filteredPhone,
    setFilteredPhone,
    isMsgErrorName,
    isMsgErrorEmail,
    isValidPhone,
    isValidPhone2,
    isOrderCreate,
  };

  console.log(transformedData)
   
  return (
    <div>
      {!dataStore.token && <Navigate to="/connexion" />}
      {!hasSuperAdmin && !hasAdmin && !hasTech && dataStore.token && (
        <Navigate to="/preparations" />
      )}
      <Container className="my-2">
        <Container className="px-3 py-0 bg-gray rounded-pill border-ui-second my-auto">
          <Row>
            <InfoTopBar infoToBarProps={infoToBarProps} />
          </Row>
        </Container>
        <div className="col-12 text-center text-ui-second font-75">
          {" "}
          {chosenLocker[0]?.slot.temperatureZone.locker.shortLocation}
        </div>
        <Container className="mt-2">
          <Row>
            {rowClasses.map((bgClass: any, index: any) => (
              <Col
                key={index}
                className={`stage ${bgClass} px-3 mx-2 h-10`}
              ></Col>
            ))}
          </Row>
        </Container>
      </Container>
      {isError ? (
        <Container className="text-center mt-3">
          <AlertIsError
            title={`Erreur : ${codeError === undefined ? "" : codeError}`}
            msg={msgError}
            colorIcon="danger"
          />
        </Container>
      ) : isLoading ? (
        <Container className="text-center mt-3">
          <DashBoardLoader />
        </Container>
      ) : (
        <Container className="pb-5 mb-5">
          {allSlot && allSlot["hydra:member"]?.length < 0 ? (
            <div className=" text-center mt-5 pt-5">
              <img
                className=""
                alt="no slot"
                src={images}
                style={{ height: "256px" }}
              />
              <div className="user-name fs-3 fw-bold text-secondary">
                Aucune réservation
              </div>
            </div>
          ) : chosenLocker?.length === 0 ? (
            <LokcerCard lockerCardProps={lockerCardProps} />
            // <div className="mt-4">
            //   {transformedData?.map((company: any, index: number) => (
            //     <React.Fragment key={index}>
            //       <div>{company.companyName}</div>
            //       {company?.locker?.map((lockers: any, indx: any) => (
            //         <React.Fragment key={indx}>
            //           <Card
            //             className="cursor border-2 border-gray p-3 mb-3 text-ui-second"
            //             onClick={() => {
            //               filteredLocker(
            //                 lockers?.location,
            //                 company.companyName
            //               );
            //               setTrigger(true);
            //               handleAddStartProduct();
            //               setAgeRestriction(false);
            //             }}
            //           >
            //             <Row className="px-1 px-sm-3">
            //               <Col className="m-auto ms-md- font-75 ps-2 px-0 mx-0 fs-5 text-md-center">
            //                 {lockers?.shortLocation}
            //               </Col>
            //               <Col>
            //                 {lockers?.temperatureZone?.map(
            //                   (slots: any, slotIndex: any) => (
            //                     <div
            //                       key={slotIndex}
            //                       className="pb-1"
            //                       style={{
            //                         display: "flex",
            //                         flexDirection: "row",
            //                       }}
            //                     >
            //                       <img
            //                         alt="Temp icon"
            //                         src={_iconFilter(slots?.keyTemp)}
            //                         style={{ width: "30px" }}
            //                       />
            //                       <span className="font-65 pt-2 ms-1 ">
            //                         <i className="ri-arrow-right-line"></i>
            //                       </span>
            //                       <div
            //                         key={Math.random()}
            //                         className="badge-hoster px-0 ms-1 pt-1"
            //                       >
            //                         <span className="font-75 fw-bold ms-2 mb-0 bg-warning rounded-pill  px-2">
            //                           {slots?.size}
            //                         </span>
            //                         <div className="my-badge px-0 font-65 fw-bold pb-0 text-light">
            //                           <span className="rounded-pill bg-green px-1 py-">
            //                             {slots?.available}
            //                           </span>
            //                         </div>
            //                       </div>
            //                     </div>
            //                   )
            //                 )}
            //               </Col>
            //             </Row>
            //           </Card>
            //         </React.Fragment>
            //       ))}
            //     </React.Fragment>
            //   ))}
            // </div>
          ) : !trigger2 ? (
            <div className="order-list mt-4">
              <ProductForm productFormProps={productFormProps} />
            </div>
          ) : (
            <div className="mt-5">
              <ClientForm clientFormProps={clientFormProps} />
            </div>
          )}
          {isScanned && (
            <div className="video-container text-center">
              <video ref={videoRef} autoPlay playsInline muted></video>
              <Button variant="warning" onClick={stopScan}>
                Stop
              </Button>
            </div>
          )}
        </Container>
      )}
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Body>
          <div className="text-center my-3">
            <p>Détail de commande</p>
            {productDetail?.map((slot: any, indx: any) => (
              <div key={indx} className={indx > 0 ? "mt-5" : "mt-1"}>
                <div className="text-start text-secondary">
                  <b className="fs-2">
                    Panier n° {indx + 1} {""} : {""}
                  </b>
                  {nameTempZones[indx] === "Froid positif"
                    ? "🍃 Fraîche"
                    : nameTempZones[indx] === "Froid négatif"
                    ? "❄ Congelée"
                    : nameTempZones[indx] === "Ambiant" && "☀️ Ambiante"}{" "}
                  ({slotSizes[indx]})
                </div>

                <Table striped className="mt-2">
                  <thead>
                    <tr>
                      <th className="text-center text-secondary">Qté</th>
                      <th className="text-center text-secondary">
                        Libellé produit
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {slot?.map((prod: any, index: any) => (
                      <tr key={index}>
                        <td className="text-center font-85">
                          {prod?.quantity}
                        </td>
                        <td className="text-center font-85">{prod?.name}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            ))}
          </div>
          <p>Voulez-vous valider cette commande ?</p>
          <div className="mt-3 text-end">
            <Button
              aria-label="Aria annuler"
              title="Annuler commande"
              variant="warning"
              onClick={cancelNewOrder}
              className="me-3  text-light rounded-pill"
            >
              Annuler
            </Button>
            <Button
              aria-label="Aria valider"
              title="Valider la commande"
              variant="transparent"
              onClick={createNewOrder}
              className="text-light bg-green rounded-pill"
            >
              {isOrderCreate && <Spinner size="sm" className="me-1" />}
              Valider
            </Button>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={showUnknowEan}
        onHide={handleCloseUnknowEan}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header className="border-0">
          <Modal.Title> "{unknowScan}"</Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-ui  text-center">
          <i className="ri-error-warning-line fs-3 text-danger "></i>{" "}
          <span className="">Ean inconnu</span>
        </Modal.Body>
        <Modal.Footer className="bg-error border-0">
          <Button
            variant="transparent"
            className="bg-green w-100"
            onClick={handleCloseUnknowEan}
          >
            Fermer
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default NewOrder3;
