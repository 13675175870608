import React from "react";
import { Container, Row, Col, Dropdown } from "react-bootstrap";
import LockerChoice from "./modals/LockerChoice";


const SearchBar = ({ searchBarProps }: any) => {
  const {
    searchOrder,
    setSearchOrder,
    setSelectedStore,
    selectedOrderCity,
    setSelectedOrderCity,
    allSlot,
    setIdStore,
  } = searchBarProps;

  const [bookingTab, setUniqueTab] = React.useState<string[]>([]);

  const [show, setShow] = React.useState<boolean>(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  React.useEffect(() => {
    const bookingLocker: string[] | any[] = allSlot?.["hydra:member"]?.map(
      (locker: any) => locker?.slot?.temperatureZone?.locker
    );
    
    const unique: string[] | any[] = [
      ...new Set(bookingLocker?.map((locker: any) => locker?.location)),
    ];

    setUniqueTab(unique);
  }, [allSlot]);

  //return selected locker short name
  const filteredCity = (place: any) => {
    const city: any[] = allSlot?.["hydra:member"]
      ?.map((locker: any) => locker?.slot?.temperatureZone?.locker)
      .filter((lockerCity: any) => lockerCity?.location === place);
    return city && city[0]?.shortLocation;
  };

  //return selected locker uri
  const filteredStore = (place: any) => {
    const city: any[] = allSlot?.["hydra:member"]
      ?.map((locker: any) => locker?.slot?.temperatureZone?.locker)
      .filter((lockerCity: any) => lockerCity?.location === place);
    return city && city[0]["@id"];
  };
  //return selected locker id
  const filteredIdStore = (place: any) => {
    const city: any= allSlot?.["hydra:member"]
      ?.map((locker: any) => locker?.slot?.temperatureZone?.locker)
      .filter((lockerCity: any) => lockerCity?.location === place);

    return city && city[0].id;
  };

  const lockerChoiceProps = {
    show,
    handleClose,
    bookingTab,
    setSelectedStore,
    setIdStore,
    setSelectedOrderCity,
    filteredStore,
    filteredCity,
    filteredIdStore,
    
  };

  return (
    <Container className={`search-ba mb-2 text-center bg-ui`}>
      <Container
        fluid
        className={` text-green ps-1 rounded-pill  my-auto  ${
          bookingTab?.length > 1 && "bg-gray pe-4 py-1"
        }`}
      >
        <Dropdown>

          <Container fluid className="px-0">
            <Row className="align-middle">
              <Col className="text-start">
                <div className="input-group ">
                  <input
                    type="text"
                    className="form-control rounded-pill bg-light "
                    name="searchInput"
                    placeholder="N° Commande..."
                    aria-label="searchOrder"
                    aria-describedby="search-order"
                    style={{
                      height: bookingTab?.length > 1 ? "35px" : "40px",
                    }}
                    value={searchOrder}
                    onChange={(e) => setSearchOrder(e.currentTarget.value)}
                  />
                  <input
                    type="text"
                    className="form-control"
                    name="hiddenSearchInput"
                    aria-label="hiddenSearchOrder"
                    aria-describedby="hidden-search-order"
                    inputMode="none"
                    autoFocus={true}
                    style={{
                      height: bookingTab?.length > 1 ? "35px" : "40px",
                      position: "absolute",
                      top: "-150px",
                      right: "0px",
                    }}
                    value={searchOrder}
                    onChange={(e) => setSearchOrder(e.currentTarget.value)}
                  />
                  {searchOrder !== "" ? (
                    <i
                      className={`ri-close-circle-fill text-warning ${
                        bookingTab?.length > 1
                          ? "delete-button fs-2"
                          : "delete-history-button fs-2"
                      } 
                      
                      `}
                      onClick={() => {
                        setSearchOrder("");
                      }}
                    ></i>
                  ) : (
                    <i
                      className={`text-ui ri-search-line fs-5 ${
                        bookingTab?.length > 1
                          ? "input-button"
                          : "input-history-button"
                      } `}
                    ></i>
                  )}
                </div>
              </Col>
              {bookingTab?.length > 1 && (
                <Col
                  xs={4}
                  md={2}
                  className="text- p-0 m-auto"
                  onClick={handleShow}
                >
              <Dropdown.Toggle as="button" id="dropdown-basic" className="border-0 bg-transparent rounded-pill text-green">
                    <i className="ri-box-3-fill fs-5 align-middle "></i>{" "}
                    <span className="font-85 text-ui-second">
                      {selectedOrderCity}{" "}
                    </span>
                  </Dropdown.Toggle>
                </Col>
              )}
            </Row>
          </Container>

          <Dropdown.Menu className="shadow">
            {bookingTab?.map((locker: any, indx: any) => (
              <Dropdown.Item
                key={indx}
                title={locker}
                className="w-100"
                onClick={() => {
                  setSelectedOrderCity(filteredCity(locker));
                  setSelectedStore(filteredStore(locker));
                }}
              >
                <Row className="item-menu text-secondary align-middle pe-3">
                  <Col xs={2} className="ms-0 ps-1">
                    {" "}
                    <i className="ri-box-3-line fs-5 align-bottom text-green me-2"></i>{" "}
                  </Col>{" "}
                  <Col
                    xs={8}
                    className="m-auto my-0 user-name ps-0 pb-0 pt-2 font-85  text-dark"
                  >
                    {filteredCity(locker)}
                  </Col>
                </Row>
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      </Container>

      <LockerChoice lockerChoiceProps={lockerChoiceProps} />
    </Container>
  );
};

export default SearchBar;
