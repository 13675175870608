import React from "react";
import "../App.css";
import "animate.css";
import userDataStore, { UserState } from "../store/userDataStore";
import { Navigate, useOutletContext } from "react-router-dom";
import { message } from "antd";
import { Container } from "react-bootstrap";
import { _getStatus, _searchWithRegex } from "../utils/functions";
import SearchBar from "../components/ui/SearchBar";
import AlertIsError from "../components/ui/warning/AlertIsError";
import PlaceHolder from "../components/ui/loading/PlaceHolder";
import OrderList from "../components/ui/OrderList";
import RetreiveDetail from "../components/ui/RetreiveDetail";
import Anomaly from "../components/ui/anomaly/Anomaly";



const ToRetrieve: React.FC = () => {
  //////////////////////////
  // booleans States
  /////////////////////////
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [isError, setIsError] = React.useState<boolean>(false);

  //////////////////////////
  // Store & context state
  /////////////////////////
  const isLogged = userDataStore((state: UserState) => state.isLogged);
  const dataStore = userDataStore((state: UserState) => state);
  const {
    setSelectedStore,
    setSelectedOrderCity,
    allSlot,
    setSelectedItem,
    selectedStore,
    selectedOrderCity,
    orderPickedUp,
    setOrderPickedUp,
    setOrderExpired,
    orderExpired,
    setIsSelectedOrder,
    idStore,setIdStore
  } = useOutletContext<any>();
  
  const userToken = localStorage.getItem("user");

  //////////////////////////
  // States
  /////////////////////////

  const [messageApi, contextHolder] = message.useMessage();

  const [selectedOrder, setSelectedOrder] = React.useState<any>("");
  const [searchOrder, setSearchOrder] = React.useState<string>("");
  const [filteredOrder, setFilteredOrder] = React.useState<any>([]);
  const [storeName, setStoreName] = React.useState<any>([]);

  const [scanCode, setScanCode] = React.useState<string>("");

  const [isAnomaly, setIsAnomaly] = React.useState<boolean>(false);
  const [msgAnomaly, setMsgAnomaly] = React.useState<string>("");

  const trigger = "retraits";
  const newStatus = "operout";


  ///////////////////////////////////////////////////
  ////Filtrage des données par locker et par livreur
  //////////////////////////////////////////////////
  const orderByStatus = orderExpired?.["hydra:member"]?.filter(
    (order: any) =>
      order?.bookingSlot?.slot?.temperatureZone?.locker &&
      order?.bookingSlot?.slot?.temperatureZone?.locker["@id"] ===
        selectedStore 
  );
  
  React.useEffect(() => {
    setIsLoading(true);
    setSelectedItem("retrieve");
    setIsSelectedOrder(false);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  
  }, []);


  React.useEffect(() => {
    if (orderByStatus) {
      setIsLoading(false);
    } else {
      if (orderByStatus?.length < 0) {
        setIsError(true);
        setIsLoading(false);
      } else {
        setIsLoading(true);
      }
    }
  }, [orderByStatus]);

  React.useEffect(() => {
    const myScan = orderPickedUp?.["hydra:member"]?.filter(
      (order: any) =>
        order?.barcode === searchOrder || order?.externalOrderId === searchOrder
    )[0];

    if (myScan) {
      setScanCode(searchOrder);
      if (myScan?.status === "picked_up") {
        if (!myScan.shippedBy) {
          setIsAnomaly(true);
          setMsgAnomaly(
            'Cette commande n\'est assignée à aucun livreur mais son statuts est "En livraison".'
          );
           setIsSelectedOrder(true)
                  setSelectedOrder(myScan);
        } else if (myScan.shippedBy.firstName !== dataStore?.firstname) {
          setIsAnomaly(true);
          setMsgAnomaly(
            "Cette commande est déjà prise en charge par " +
              myScan?.shippedBy.firstName
          );
           setIsSelectedOrder(true)
                  setSelectedOrder(myScan);
        } else if (
          myScan.bookingSlot?.slot?.temperatureZone?.locker &&
          myScan.bookingSlot?.slot?.temperatureZone?.locker["@id"] !==
            selectedStore
        ) {
          setIsAnomaly(true);
          setMsgAnomaly(
            "Commande pour : " +
              myScan?.bookingSlot?.slot?.temperatureZone?.locker?.location
          );
           setIsSelectedOrder(true)
                  setSelectedOrder(myScan);
        } else {
          //OK
           setIsSelectedOrder(true)
                  setSelectedOrder(myScan);
        }
      } else if (myScan?.status === "created") {
        setIsAnomaly(true);
        setMsgAnomaly("Cette commande est en cours de préparation");
         setIsSelectedOrder(true)
                  setSelectedOrder(myScan);
      } else if (myScan?.status === "ready_for_delivery") {
        if (
          myScan.bookingSlot?.slot?.temperatureZone?.locker &&
          myScan.bookingSlot?.slot?.temperatureZone?.locker["@id"] !==
            selectedStore
        ) {
          setIsAnomaly(true);
          setMsgAnomaly(
            "Commande pour : " +
              myScan?.bookingSlot?.slot?.temperatureZone?.locker?.location
          );
           setIsSelectedOrder(true)
                  setSelectedOrder(myScan);
        } else {
          setIsAnomaly(true);
          setMsgAnomaly("Cette commande est sur le quai des livraisons");
           setIsSelectedOrder(true)
                  setSelectedOrder(myScan);
        }
      } else if (
        myScan?.status === "operin" ||
        myScan?.status === "reminder" ||
        myScan?.status === "overtimedue" ||
        myScan?.status === "overtime"
      ) {
        setIsAnomaly(true);
        setMsgAnomaly(
          "Cette commande est en status : " +
            _getStatus(myScan?.status) +
            ", consultez l'historique. Code barre : " +
            myScan?.barcode
        );
         setIsSelectedOrder(true)
                  setSelectedOrder(myScan);
        setIsSelectedOrder(true)
      }
    } else {
      //no exist
      _searchWithRegex(searchOrder, orderByStatus, setFilteredOrder);
    }
  }, [searchOrder]);

  React.useEffect(() => {
    setStoreName(
      allSlot?.["hydra:member"] &&
        allSlot?.["hydra:member"]?.filter(
          (locker: any) =>
            locker?.slot?.temperatureZone?.locker["@id"] === selectedStore
        )
    );
  }, [selectedStore]);

  //////////////////////////
  // Component Props
  /////////////////////////
  const searchBarProps = {
    searchOrder,
    setSearchOrder,
    selectedStore,
    setSelectedStore,
    selectedOrderCity,
    setSelectedOrderCity,
    allSlot,
    setIdStore,
  }

  const orderListProps = {
    filteredOrder,
    setSelectedOrder,
    searchOrder,
    setSearchOrder,
    orderByStatus,
    storeName,
    setIsSelectedOrder,
  }

  const scanPageProps = {
    selectedOrder,
    messageApi,
    setSelectedOrder,
    newStatus,
    setOrderPickedUp,
    setOrderExpired,
    setSearchOrder,
    trigger,
  }
  const anomalyProps = {setSelectedOrder, setIsAnomaly, selectedOrder, scanCode, msgAnomaly}




  return (
    <>
      {!selectedOrder && !isAnomaly && (
        <div className="sticky-top pt-2 bg-ui">
          <SearchBar searchBarProps={searchBarProps} />
        </div>
      )}
      <Container fluid className="cde App px-0">
        {contextHolder}
        {(!dataStore.token || !dataStore?.company_name) && (
          <Navigate to="/connexion" />
        )}

        {isError ? (
          <Container className="text-center mt-5">
            <AlertIsError
              title="Une erreur s'est produite"
              msg="Vérifiez votre connexion internet ou contactez votre administrateur."
              colorIcon="danger"
            />
          </Container>
        ) : isLoading ? (
          <Container className="text-center mt-2">
            <PlaceHolder itemCount={10} />
          </Container>
        ) : (
          <>
            {isAnomaly ? (
              <Container fluid className="pb-5">
                <div className="col-12 pb-0 text-center font-75">
                  {storeName &&
                    storeName[0]?.slot?.temperatureZone?.locker?.location}
                </div>
                <Anomaly anomalyProps={anomalyProps} />
              </Container>
            ) : !selectedOrder ? (
              <>
                <OrderList orderListProps={orderListProps} />
              </>
            ) : (
              <RetreiveDetail scanPageProps={scanPageProps} />
            )}
          </>
        )}
      </Container>
    </>
  );
};

export default ToRetrieve;
